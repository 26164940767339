<!--
 * @Author: RONGWEI PENG
 * @Date: 2021-11-08 09:56:01
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-11-08 11:04:26
 * @FilePath: /dsf-product-nc/packages/@dsf/cli-nc/pc/components/mine/mytaskDetail.vue
-->
<template>
  <div class="dsf_ncpc_task_detail" v-if="isDesign">
    <div class="title_box"><div class="title">第二课时课后作业</div></div>
    <div class="detail_innerbox">
      <div class="detail_date detail_i">
        <h5>周期</h5>
        <span>2009-01-15 至 2021-09-21</span>
      </div>
      <div class="detail_desc detail_i">
        <h5>描述</h5>
        <span>
          成设养难类多消角你这最强党示二农自统九影七张始立验因区地片件际法到容眼叫意单资总他王离还拉据管五达单再。住设声委济情六极议持风还百部史理验色低型系前格表别儿资准近水强米难此记程美号易实眼器却般步在把省现。响并例段气数运流说周么率标示认展论性事红意导比务入低国量建温步想满件体使于管达白义飞值立各林山看真金铁色海太教已研价华。化程质行管部只当成革具风她花地那十大状转积火史龙点况火开代器立识机然去周林才门老基题育各于常年车直对许圆者织无分元社共级但打。除应观低老群界得五改结是其养没质家节半入见度记美关龙见满导例期引众五全具近用厂律都许知因治走织查传共部很圆圆。
        </span>
      </div>
      <div class="detail_request detail_i">
        <h5>学时要求</h5>
        <span>50学时</span>
      </div>
      <ul class="detail_wrap detail_course">
        <li class="detail_items">
          <div class="items_imgs"><dsf-nc-image></dsf-nc-image></div>
          <div class="items_right">
            <div class="item_name ell">产业互联网——构建智能+时代数字生态新图景</div>
            <div class="items_bottom_box">
              <div class="items_bottom_box_left">
                <div class="item_center">
                  <div class="teacher">张三老师</div>
                  <div class="job">xxxx高级讲师</div>
                </div>
                <div class="footer">
                  <div class="xs">
                    <i class="icon iconfont icon-shijian2"></i>
                    2学时
                  </div>
                  <div class="ckl">
                    <i class="icon iconfont icon-yanjing1"></i>
                    200
                  </div>
                </div>
              </div>
              <div class="items_bottom_box_right">
                <div class="rate">42%</div>
                <div class="font">当前进度</div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <ul class="detail_wrap detail_exams">
        <li class="detail_items" v-for="item in 4" :key="item">
          <div class="title">结业考试</div>
          <div class="date">1997-04-13 12:00～13:00（限时50分钟）</div>
          <div class="score">
            <div class="total_score">总分：100分</div>
            <div class="pass_score">及格分数：60分</div>
          </div>
          <div class="score_num">
            <div class="total_num">考试次数：5次</div>
            <div class="have_num">已考/剩余次数：1/4次</div>
          </div>
          <div class="enter_exam" @click="enterExam"><el-button>进入考试</el-button></div>
        </li>
      </ul>
      <div class="detail_award detail_i">
        <h5>任务奖励</h5>
        <div class="complete_certificate">
          <DsfNcCredential :certificate-src="certificateSrc" />
        </div>
      </div>
    </div>
  </div>
  <div class="dsf_ncpc_task_detail" v-else>
    <div class="title_box">
      <div class="title">{{ taskDetail.task_name }}</div>
    </div>
    <div class="detail_innerbox">
      <div class="detail_date detail_i">
        <h5>周期</h5>
        <!-- <span>{{ setDate(taskDetail.task_cycle_sdate, taskDetail.task_cycle_edate, "至") }}</span> -->
        <span>{{ dsf.date.format(taskDetail.task_cycle_sdate, "yyyy-mm-dd") }} 至 {{ dsf.date.format(taskDetail.task_cycle_edate, "yyyy-mm-dd") }}</span>
      </div>
      <div class="detail_desc detail_i">
        <h5>描述</h5>
        <span>
          {{ taskDetail.task_desc || "暂无数据" }}
        </span>
      </div>
      <div class="detail_request detail_i" v-if="taskDetail.task_type_value == 3">
        <!-- 学时任务才有学时 -->
        <h5>学时要求</h5>
        <span>{{ taskDetail.study_hour || 0 }}学时</span>
      </div>
      <div class="detail_request detail_i" v-if="taskDetail.task_type_value == 3">
        <!-- 学时任务才有学时 -->
        <h5>获得学时</h5>
        <span>{{ taskDetail.progress || 0 }}学时</span>
      </div>
      <div class="detail_desc detail_i" v-if="taskDetail.task_content">
        <!-- 任务类型为考试任务或课程任务才有值 -->
        <h5>任务内容</h5>
        <ul class="detail_wrap detail_course" v-if="taskDetail.task_type_value == 1">
          <li class="detail_items" v-for="item in taskDetail.task_content" :key="item._id" @click="handleClickItem(item, taskDetail.task_type_value)">
            <div class="items_imgs"><dsf-nc-image :src="item.cover | imgFormat"></dsf-nc-image></div>
            <div class="items_right">
              <div class="item_name ell">{{ item.title || "" }}</div>
              <div class="items_bottom_box">
                <div class="items_bottom_box_left">
                  <div class="item_center">
                    <div class="teacher">{{ item.teachername || "" }}</div>
                    <div class="job ell">{{ item.job || "" }}</div>
                  </div>
                  <div class="footer">
                    <div class="xs">
                      <i class="icon iconfont icon-shijian2"></i>
                      {{ item.period || 0 }}学时
                    </div>
                    <div class="ckl">
                      <i class="icon iconfont icon-yanjing1"></i>
                      {{ item.view_totle || 0 }}
                    </div>
                  </div>
                </div>
                <div class="items_bottom_box_right">
                  <div class="rate">{{ item.progress || "0" }}%</div>
                  <div class="font">当前进度</div>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <ul class="detail_wrap detail_exams" v-if="taskDetail.task_type_value == 2">
          <li class="detail_items" v-for="item in taskDetail.task_content" :key="item._id">
            <div class="title">结业考试</div>
            <div class="detail_inner">
              <!-- <div class="date">1997-04-13 12:00～13:00（限时50分钟）</div> -->
              <div class="score">
                <div class="total_score">总分：{{ item.score || 100 }}分</div>
                <div class="pass_score">及格分数：{{ item.pass_score || 60 }}分</div>
              </div>
              <div class="score_num">
                <div class="total_num">考试次数：{{ (item.exam_count * 1 || 0) + (item.surplus_count * 1 || 0) }}次</div>
                <div class="have_num">已考/剩余次数：{{ item.exam_count || 0 }}/{{ item.surplus_count || 0 }}次</div>
              </div>
              <div class="enter_exam" @click="handleClickItem(item, taskDetail.task_type_value)">
                <el-button>{{ Number(item.surplus_count) != 0 ? "进入考试" : "查看" }}</el-button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- 考核要求： 是考核 && 学时任务 -->
      <div class="detail_i" v-if="taskDetail.is_assess_value && taskDetail.task_type_value == 3">
        <h5>考核要求</h5>
        <ul class="detail_wrap detail_exams" v-if="taskDetail.task_assess_exam">
          <li class="detail_items" v-for="item in taskDetail.task_assess_exam" :key="item._id">
            <div class="title">结业考试</div>
            <div class="detail_inner">
              <!-- <div class="date">1997-04-13 12:00～13:00（限时50分钟）</div> -->
              <div class="score">
                <div class="total_score">总分：{{ item.score }}分</div>
                <div class="pass_score">及格分数：{{ item.pass_score }}分</div>
              </div>
              <div class="score_num">
                <div class="total_num">考试次数：{{ (item.exam_count * 1 || 0) + (item.surplus_count * 1 || 0) }}次</div>
                <div class="have_num">已考/剩余次数：{{ item.exam_count || 0 }}/{{ item.surplus_count || 0 }}次</div>
              </div>
              <!-- <div :class="['enter_exam', taskDetail.complete_rate != 100 ? 'gray_btn' : '']" @click="taskDetail.complete_rate == 100 && enterExam(item)">
                <el-button>进入考试</el-button>
              </div> -->
              <div :class="['enter_exam']" @click="enterExam(item)">
                <el-button>{{ Number(item.surplus_count) != 0 ? "进入考试" : "查看" }}</el-button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="detail_award detail_i certificate_wrap" v-if="taskDetail.is_reward_value && taskDetail.task_type_value == 3 && certificateSrc">
        <h5>任务奖励</h5>
        <div :class="['complete_certificate', !certificateSrc ? 'gray_btn' : '']">
          <DsfNcCredential :certificate-src="certificateSrc" />
        </div>
        <div :class="['re_certificate', !taskCertificateState ? 'gray_btn' : '']" @click="taskCertificateState && clickGetCertificate()"><el-button>重新生成证书</el-button></div>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcPcTaskDetailGz",
  ctrlCaption: "我的任务详情",
  mixins: [$mixins.control],
  props: {},

  data() {
    return {
      certificateSrc: "",
      taskId: "",
      taskDetail: {},
      count: 0,
      taskCertificateState: false,
    };
  },

  components: {},

  mounted() {
    if (!this.isDesign) {
      /* 学时任务id: 107ddaf863344e37b08a5bb6100a2e44
        课程任务id： 6a72f66b541b438c9190cd4bf55b6e84
        考试任务： a150a099818c48fdbe366c28026e927c
      */

      this.taskId = this.$route.query.taskId || "107ddaf863344e37b08a5bb6100a2e44";
      this.renderData();
    }
  },

  methods: {
    certificateInitData() {
      const certificateUrl = "nc/studytask/taskCertificate";
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(certificateUrl, { taskId: this.taskId }, $$webRoot.nc)
        .done(res => {
          let { data, success } = res;
          if (!success) {
            this.error(res);
          } else {
            this.count = 1;
            data && this.dsf.layer.message(data, true);
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    renderData() {
      const loading = dsf.layer.loading();
      const sourceUrl = "/nc/studytask/getTaskDetailById";
      this.dsf.http
        .get(sourceUrl, { taskId: this.taskId }, $$webRoot.nc)
        .done(res => {
          let { data, success } = res;
          if (success) {
            this.taskDetail = data;

            /* 进页面是那个certificate_url（证书地址）有值才去加载证书图片，没有值就根据任务详情里面 task_assess_exam(考核考试对象)要有值，并且这个对象里面的ispass（考试是否通过）为1才可以去生成证书 */
            // if (data.task_assess_exam && data.task_assess_exam.length > 0) {
            //   data.task_assess_exam.forEach(item => {
            //     if (item.ispass == 1 || data.status_value == 2) {
            //       this.taskCertificateState = true;
            //       if (!data.certificate_url && this.count == 0) {
            //         this.certificateInitData();
            //       }
            //     }
            //   });
            // }

            /*如果没有考核，学时达到了就完成了也不需要去考试，如果有考核考试的情况下这个参数是学时完成率complete_rate，只有完成率到100才可以去考试，考试通过了status_value==2状态就完成了，这时才可以去重新生成证书*/
            if (this.taskDetail.task_type_value == 3) {
              if (
                (this.taskDetail.task_assess_exam && this.taskDetail.task_assess_exam.length > 0 && this.taskDetail.status_value == 2) ||
                ((!this.taskDetail.task_assess_exam || this.taskDetail.task_assess_exam.length == 0) && this.taskDetail.complete_rate == 100)
              ) {
                this.taskCertificateState = true;
                if (!data.certificate_url && this.count == 0) {
                  this.certificateInitData();
                }
              }
            }
          } else {
            this.dsf.layer.message(res.message || "获取数据异常", false);
          }
        })
        .error(response => {
          this.dsf.layer.message(response.message || "获取数据异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    clickGetCertificate() {
      this.taskCertificateState = false;
      this.certificateInitData();
    },
    handleClickItem(item, type) {
      let url = "";
      let id = "";
      let state = 2;
      switch (type) {
        case "1":
          // 课程
          id = item.nc_courses_info_id;
          url = `#/pc/nc/pagecourse/coursePlayer?id=${id}`;
          this.$open({ url, state });
          break;
        case "2":
          // 考试
          id = item.exam_sjgl_id;
          this.toExamDetail(id, 3); // 任务考试：考试的类型传3
          break;
      }
    },
    toExamDetail(id, type) {
      const loading = dsf.layer.loading();
      /**
       * @param businessType 专题班1 考核2 单元考试1 任务考试 3
       * @param deviceType pc mobile
       */
      let params = {
        examId: id, //考试id
        businessId: this.taskId, //业务id
        businessType: type, // 任务考试：3 ； 学时任务：考试类型 'task_assess'
        deviceType: "pc",
      };
      this.dsf.http
        .get("ncexam/api/getExamUrl", params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            const path = dsf.url.getWebPath(res.data, $$webRoot.exam);
            // window.open(path);
            this.$open({ url: `${location.origin + path}`, state: 2 });
          } else {
            dsf.layer.message(res.message || "获取考试异常", false);
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "获取考试异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    enterExam(item) {
      let id = item.exam_sjgl_id;
      let type = this.taskDetail.task_type_value;
      if (type == 3) {
        // 学时任务： 考试类型传‘task_assess’
        type = "task_assess";
      }

      this.toExamDetail(id, type);
    },
  },
});
</script>
