<template>
  <label class="dsf-college-home-header-search" :class="{ show: true }">
    <!-- <i class="iconfont icon-sousuo" @click.self="submit"></i> -->
    <input id="input" ref="input" v-model.trim="keywords" :placeholder="placeholder" type="text" @focus="focus" @click.self="focus" @blur="blur" @input="input" @keypress.enter="doSearch" />
    <span class="search_text" @click="doSearch">搜索</span>
    <div class="history" v-show="isFocus" @click="historyblur">
      <ul class="search-history-wrap" id="SearchHistoryResult">
        <li v-for="item in historyList" :key="item['nc_search_history_id']">
          <div>
            <i class="search-history-item-icon-time" @click.self="close"></i>
            <span class="SearchItem" @click.stop="clickhot(item.title)">{{ item.title }}</span>
          </div>
          <div class="icon iconfont icon-guanbi2 DelHistoryItem" data-idx="0" @click.self="reset(item['nc_search_history_id'])"></div>
        </li>
        <span class="search-clear" v-show="historyList.length" @click.self="reset()">清空历史</span>
      </ul>
      <label class="search-hot-title">热门搜索</label>
      <ul class="search-hot-wrap" id="SearchHotResult">
        <li v-for="(item, index) in hotlist" :key="index" @click="clickhot(item.title)">
          <i class="search-hot-icon"></i>
          {{ item.title }}
        </li>
      </ul>
    </div>
  </label>
</template>

<script>
export default {
  name: "HomeNcSearch",
  props: {
    placeholder: {
      type: String,
      default: "请输入你需要搜索的内容",
    },
    search: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      isFocus: false,
      historyshow: false,
      keywords: "",
      historyList: [],
    };
  },
  computed: {
    show() {
      return this.isFocus || this.keywords;
    },
  },
  // beforeUpdate() {
  //   this.$nextTick(() => {
  //     this.$refs.input.value = this.keywords;
  //   });
  // },
  watch: {},
  created() {
    this.hotkeyword();
    this.$nextTick(() => {
      if (sessionStorage.getItem("keyword")) {
        this.keywords = decodeURI(decodeURI(sessionStorage.getItem("keyword"))).trim();
        this.$refs.input.value = decodeURI(decodeURI(sessionStorage.getItem("keyword"))).trim();
      } else {
        this.keywords = "";
        this.$refs.input.value = "";
      }
    });
  },
  beforeDestroy() {
    eventBus.$emit("allsearch", [this.$refs.input.value, location.hash]);
  },
  methods: {
    islogin() {
      this.$openDialog({
        title: "",
        noTitle: true,
        width: "800px",
        height: dsf.config.setting_nc_other_dx_login == '1' ? "430px" : "370px",
        class: "test",
        params: {
          path: "/pc/nc/pagelogin/dialogLogin",
        },
      });
    },
    close() {
      this.$refs.input.blur();
    },
    submit() {
      if (this.isFocus) {
        // this.btClick = true;
        this.doSearch();
      }
    },
    focus() {
      if (!isone) {
        this.getsearchHistory();
        console.log("getsearchHistory ");
      }
      let isone = false;
      this.isFocus = true;
      console.log("focus");
      isone = true;
      // this.historyshow = true;
    },
    historyblur() {
      this.$refs.input.focus();
    },
    blur() {
      setTimeout(() => {
        // if (!this.btClick) {
        this.isFocus = false;
        // }
        // this.btClick = false;
      }, 300);
    },
    input() {
      this.$nextTick(() => {
        if (this.$refs.input.value.trim() == "") {
          sessionStorage.setItem("keyword", "");
        } else {
          sessionStorage.setItem("keyword", this.$refs.input.value);
        }
      });
    },
    doSearch() {
      if (dsf.getCookie("isLogin")) {
        let keyword = encodeURI(encodeURI(this.$refs.input.value));
        if (location.hash.split("?")[0] != "#/pc/nc/page/pc/commonpage/allsearch") {
          console.log(keyword);
          sessionStorage.setItem("keyword", keyword);
          window.open(`${location.origin}/ncIndex.html#/pc/nc/page/pc/commonpage/allsearch?search=${keyword}`);
        } else {
          sessionStorage.setItem("keyword", keyword);
          eventBus.$emit("allsearch", [this.$refs.input.value, location.hash]);
        }
        this.close();
        this.getinsertSearchHistory();
      } else {
        this.islogin();
      }

      // if (this.search) {
      //   let isClear = this.search(this.keywords);
      //   if (isClear.then) {
      //     isClear.then((res) => {
      //       if (res) {
      //         // this.keywords = "";
      //         this.$refs.input.blur();
      //       }
      //     });
      //   } else if (isClear === true) {
      //     this.keywords = "";
      //     this.$refs.input.blur();
      //   }
      // } else {
      //   this.$emit("search", this.keywords);
      // }
    },
    // 清空历史
    reset(id) {
      let that = this;
      const mainUrl = "nc/search/deleteSearchHistory";
      const params = {
        id: id ? id : "",
        isall: id ? "" : 1,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.getsearchHistory();
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          that.dsf.layer.closeLoading(loader);
        });
    },
    // 保存搜索记录
    getinsertSearchHistory() {
      let that = this;
      const mainUrl = "/nc/search/insertSearchHistory";
      const params = {
        searchContent: that.keywords,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            console.log(res.data);
            that.dataList = res.data;
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    // 获取搜索记录
    getsearchHistory() {
      if (!dsf.getCookie("userId")) return;
      let that = this;
      const mainUrl = "/nc/search/searchHistory";
      const params = {};
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            console.log(res.data);
            that.historyList = res.data;
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },

    hotkeyword() {
      let that = this;
      const mainUrl = "/search/hot/getHotData";
      const params = {};
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            // console.log(res.data, "hotkeyword");
            that.hotlist = res.data;
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    clickhot(item) {
      this.keywords = item;
      this.$refs.input.value = item;
      this.doSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
.dsf-college-home-header-search {
  border: 1px solid #e2e2e2 !important;
  input {
    left: 12px;
    padding-right: 5px;
    width: 212px;
    &::placeholder {
      color: #c8c8c8 !important;
    }
  }
  .search_text {
    position: absolute;
    right: 0px;
    border-radius: 0 18px 18px 0;
    background: #fd0017;
    color: #fff;
    padding: 0 16px;
    cursor: pointer;
  }
}
</style>
