<template>
  <div class="dsf_nc_assess">
    <div class="list">
      <div class="item" v-for="(item, index) in list" :key="index" @click="godital(index)" v-show="index > 0">
        <div class="imgbox">
          <img :src="item.imgUrl" alt="" />
        </div>
        <div class="info">
          <p>{{ item.title }}</p>
          <div class="btn">点击查看</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcGzAssess",
  ctrlCaption: "素质评估",
  mixins: [$mixins.control],
  data() {
    return {
      list: [
        {
          title: "素质评估",
          imgUrl: require("../../../assets/styles/img/pcimg/guizhou/img-szpg.png"),
        },
        {
          title: "有声图书馆",
          imgUrl: require("../../../assets/styles/img/pcimg/guizhou/img-ystsg.png"),
        },
        {
          title: "在线书店",
          imgUrl: require("../../../assets/styles/img/pcimg/guizhou/img-zxsd.png"),
        },
        {
          title: "直播课堂",
          imgUrl: require("../../../assets/styles/img/pcimg/guizhou/img-zbkt.png"),
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 是否登录
    islogin() {
      this.$openDialog({
        title: "",
        noTitle: true,
        width: "800px",
        height: dsf.config.setting_nc_other_dx_login == '1' ? "430px" : "370px",
        class: "test",
        params: {
          path: "/pc/nc/pagelogin/dialogLogin",
        },
      });
    },
    godital(index) {
      if (dsf.getCookie("isLogin")) {
        if (index == 0) {
          if (!dsf.getCookie("userId")) {
          }
          dsf.layer.message("暂未开放，敬请期待");
          return
          window.open(`${location.origin}/ncIndex.html#/pc/nc/page/pc/commonpage/organization`);
        } else if (index == 1) {
          dsf.layer.message("系统升级中，敬请期待");
          return
          let that = this;
          const mainUrl = "/inc/auth/login";
          const params = {
            type: "pc",
          };
          that.dsf.http
            .get(mainUrl, params)
            .done(function (res) {
              if (res.success) {
                // dsf.layer.pc.message({
                //   message: "您即将离开贵州网院",
                //   duration: 3000,
                // });
                // setTimeout(() => {
                  window.open(res.data, "_blank");
                // }, 3000);
              } else {
                that.$message({
                  message: res.message || "获取列表数据异常",
                  type: "warning",
                });
              }
            })
            .error(function (error) {
              // that.dsf.layer.toast("服务器异常", false);
            })
            .always(function () {
              // that.dsf.layer.closeLoading(loader);
            });
        } else if (index == 2) {
          dsf.layer.message("系统升级中，敬请期待");
          return
          let that = this;
          const mainUrl = "/inc/nc/zxsd/getZxsdUrl";
          const params = {};
          that.dsf.http
            .get(mainUrl, params)
            .done(function (res) {
              if (res.success) {
                console.log(res.data);
                window.open(res.data.url, "_blank");
              } else {
                that.$message({
                  message: res.message || "获取列表数据异常",
                  type: "warning",
                });
              }
            })
            .error(function (error) {
              // that.dsf.layer.toast("服务器异常", false);
            })
            .always(function () {
              // that.dsf.layer.closeLoading(loader);
            });
        }else if (index == 3) {
         this.$open({
          url: "/ncIndex.html#/pc/nc/pagelive/wonderfulLive",
          state: 2,
         })
        }
      } else {
        this.islogin();
      }
    },
    showList() {
      let that = this;
      const mainUrl = "/cms/articles/queryArticlesByCatalogCode";
      const params = {
        catalogCode: "003",
        pageNumber: 1,
        pageSize: 15,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success && res.data.length > 0) {
            that.list = res.data;
            that.contentTtle = res.data[0].title;
            that.$nextTick(function () {
              that.showDetails(res.data[0], 0);
            });
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          that.dsf.layer.closeLoading(loader);
        });
    },
    showDetails(item, index) {
      console.log(item);
      this.active = index;
      this.contentTtle = item.title;

      let that = this;
      const mainUrl = "/cms/articles/queryArticlesById";
      const params = {
        id: item.id,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.datahtml = res.data.content;
            console.log("获取高度");
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>

<style></style>
