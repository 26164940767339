<template>
  <div class="text-login">
    <div class="login-box-info margin-top">
      <!-- <dsf-icon name="icon_zhanghao" /> -->
      <div class="img">
        <img
          src="../../../assets/styles/img/pcimg/guizhou/login-name.png"
          alt=""
        />
      </div>
      <input
        type="text"
        :placeholder="nameHolder"
        v-model="loginForm.username"
        autocomplete="new-password"
      />
    </div>
    <div class="login-box-info">
      <!-- <dsf-icon name="suozi" /> -->
      <div class="img">
        <img
          src="../../../assets/styles/img/pcimg/guizhou/login-pwd.png"
          alt=""
        />
      </div>
      <input
        type="password"
        :placeholder="pwdHolder"
        v-model="loginForm.password"
        autocomplete="new-password"
        v-if="!showPwd"
      />
      <input
        type="text"
        :placeholder="pwdHolder"
        v-model="loginForm.password"
        autocomplete="new-password"
        v-else
      />
      <dsf-icon
        name="biyan"
        class="show-pwd"
        @click="changePwdType"
        v-if="!showPwd"
      />
      <dsf-icon
        name="yanjing1"
        class="show-pwd"
        @click="changePwdType"
        v-else
      />
    </div>
    <div class="login-box-info login-code">
      <div class="login-code-wrap">
        <!-- <dsf-icon name="yanzhengma1" /> -->
        <div class="img">
          <img
            src="../../../assets/styles/img/pcimg/guizhou/login-code.png"
            alt=""
          />
        </div>
        <input
          @keydown.enter="submitTextLogin"
          type="text"
          :placeholder="codeHolder"
          v-model="loginForm.code"
        />
      </div>
      <img
        v-if="imgCode"
        :src="imgCode"
        alt=""
        class="img-code"
        @click="getImgCode()"
      />
    </div>
    <div class="login-box-info login-code" v-if="setting_nc_other_dx_login">
      <div class="login-code-wrap">
        <div class="img">
          <img
            src="../../../assets/styles/img/pcimg/guizhou/login-code.png"
            alt=""
          />
        </div>
        <input
          type="text"
          @keydown.enter="submitTextLogin"
          :placeholder="telCodeHolder"
          v-model="loginForm.phoneCode"
        />
      </div>
      <el-button
        type="info"
        class="tel_code_btn"
        @click="getTelCode"
        >{{ telCodeText }}</el-button
      >
      <!-- <dsf-button class="tel_code_btn">获取手机验证码</dsf-button> -->
    </div>
    <div class="login-box-handle">
      <!-- <div>
        <el-checkbox v-model="loginForm.checked" @change="setRemindState"
          >记住密码</el-checkbox
        >
      </div> -->
      <div @click="toChangePwd">忘记密码</div>
      <!-- <div
        @click="toRegister"
        v-if="dsf.config.setting_nc_reg_is_register == 1"
      >
        <div class="hint">
          没有账号？
          <span class="pointer">去注册</span>
        </div>
      </div> -->
    </div>
    <div class="login-box-button">
      <el-button
        :loading="loading"
        type="info"
        native-type="submit"
        size="small"
        @click="loginHandle"
        class="loginbtn"
        >登 录</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sourceUrl: {
      type: String,
      default: "/login/login",
    },
    nameHolder: {
      type: String,
      default: "请输入账号",
    },
    pwdHolder: {
      type: String,
      default: "请输入密码",
    },
    codeHolder: {
      type: String,
      default: "动态验证码",
    },
    telCodeHolder: {
      type: String,
      default: "手机验证码",
    },
    toUrl: {
      type: String,
      default: "",
    },
    loginSuccess: {
      type: Function,
      required: false,
    },
    loginFail: {
      type: Function,
      required: false,
    },
    isreloadCurrentPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
        code: "",
        phoneCode: "",
        checked: Boolean(localStorage.getItem("isRemind")) || false,
      },
      tabPosition: "top",
      rules: {
        username: [
          { required: true, message: "请输入您的账号", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码长度必须大于6位", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      loading: false,
      imgCode: null,
      userMsg: localStorage.getItem("remindUserMsg")
        ? JSON.parse(localStorage.getItem("remindUserMsg"))
        : null,
      checkPwd: true,
      showPwd: false,
      loginHandle: null,
      codeLoading: false,
      telTimeCount: 60,
      telCodeText: '获取手机验证码',
    };
  },
  components: {},
  computed: {
    setting_nc_other_dx_login(){
      return dsf.config.setting_nc_other_dx_login == '1';
    },
  },
  created() {
    let that = this;
    // document.onkeydown = function (e) {
    //   let key = e.keyCode;
    //   if (key == 13) that.loginHandle();
    // };
    if (this.userMsg && this.loginForm.checked) {
      const { userName, password } = this.userMsg;
      this.loginForm.username = userName;
      this.loginForm.password = password;
      this.checkPwd = false;
    }
  },
  mounted() {
    this.getImgCode();
    this.loginHandle = _.throttle(this.submitTextLogin, 1000);
  },
  methods: {
    changePwdType() {
      this.showPwd = !this.showPwd;
    },
    getImgCode() {
      this.imgCode = dsf.url.getWebPath("code/image?t=" + new Date().getTime());
    },
    getTelCode(){
      let that = this;
      const { username, password, code } = this.loginForm;
      if (username === "") {
        dsf.layer.message("账号不能为空！", false);
        return;
      }
      if (password === "") {
        dsf.layer.message("密码不能为空！", false);
        return;
      }
      if (code === "") {
        this.getImgCode();
        dsf.layer.message("动态验证码不能为空！", false);
        return;
      }
      if (this.codeLoading) return;
      this.codeLoading = true;
      const loading = dsf.layer.pc.loading();
      dsf.http
        .post('/login/loginGetVCode', {
          userName: username,
          password: dsf.md5(dsf.md5(password)),
          code,
        }, $$webRoot.nc)
        .done(({ message, state, data, success }) => {
          if (success) {
            //没有手机号
            if(state == '20999'){
              error(message);
              let timer = setTimeout(() => {
                clearTimeout(timer);
                dsf.layer.openDialog({
                  title: "手机号绑定",
                  width: "450px",
                  showClose: true,
                  showCancelButton: false,
                  closeOnClickModal: false,
                  closeOnPressEscape: false,
                  params: {
                    path: `/pc/nc/page/pc/login/phonebind?name=${data.name}&code=${data.code}&userId=${data.userId}&loginName=${data.loginName}`,
                  },
                });
              }, 1000);
              return
            }

            dsf.layer.pc.message(message);
            let timer = setInterval(() => {
              this.telTimeCount--;
              if(this.telTimeCount <= 0) {
                this.telTimeCount = 60;
                this.codeLoading = false;
                this.telCodeText = '重新获取';
                clearInterval(timer);
              }else{
                this.telCodeText = `${this.telTimeCount}秒后重新获取`;
              }
            }, 1000)
          }else{
            error(message);
          }
        })
        .error((err) => {
          error(err.message);
        })
        .always((res) => {
          dsf.layer.pc.closeLoading(loading);
        });
        function error(message){
          dsf.layer.pc.message(message, false);
          that.codeLoading = false;
          that.getImgCode();
        }
    },
    submit() {
      this.loginHandle();
    },
    submitTextLogin() {
      if (this.loading) return;
      
      const { username, password, code, checked, phoneCode } = this.loginForm;
      if (username === "") {
        dsf.layer.message("账号不能为空！", false);
        return;
      }
      if (password === "") {
        dsf.layer.message("密码不能为空！", false);
        return;
      }
      if (code === "") {
        this.getImgCode();
        dsf.layer.message("动态验证码不能为空！", false);
        return;
      }
      if (this.setting_nc_other_dx_login && phoneCode === "") {
        dsf.layer.message("手机验证码不能为空！", false);
        return;
      }
      if (this.loading) return;
      this.loading = true;
      let params = {
        userName: username,
        password: dsf.md5(dsf.md5(password)),
        // code: code,
        code: this.setting_nc_other_dx_login ? phoneCode : code,
        // typeValue: 1,
        typeValue: this.setting_nc_other_dx_login ? 20 : 1,
      };
      dsf.http
        .post(this.sourceUrl, params, $$webRoot.nc)
        .done(({ message, data, success }) => {
          if (success) {
            const toPath = data.index_url;
            const curPath = location.hash;
            dsf.layer.pc.message(message, true);
            dsf.cookies.set("isLogin", 1);
            this.loginSuccess?.();
            localStorage.setItem(
              "remindUserMsg",
              checked
                ? JSON.stringify({ userName: username, password: password })
                : ""
            );
            dsf.storage.session.set('pathname', window.location.pathname)
            let timer = setTimeout(() => {
              if (this.isreloadCurrentPage) {
                // 登录成功
                let that = this;
                const mainUrl = "/nc/user/checkVerification";
                const params = {};
                that.dsf.http
                  .get(mainUrl, params)
                  .done(function (res) {
                    if (res.success) {
                      console.log(res.data, "res.data11");
                      if (Number(dsf.getCookie("flag"))) {
                        //弱密码强制修改密码
                         window.openInfo = dsf.layer.openDialog({
                          title: "修改密码",
                          width: "450px",
                          height: "450px",
                          showClose: false,
                          showCancelButton: false,
                          closeOnClickModal: false,
                          closeOnPressEscape: false,
                          params: {
                            path: "/pc/nc/page/pc/login/changePwd",
                          },
                        });
                      } else if (!Number(dsf.getCookie("verificationText"))) {
                        //
                        dsf.layer.openDialog({
                          title: "填写手机号后四位",
                          width: "450px",
                          height: "460px",
                          showClose: false,
                          showCancelButton: false,
                          closeOnClickModal: false,
                          closeOnPressEscape: false,
                          params: {
                            path: "/pc/nc/page/pc/login/phonehsw",
                          },
                        });
                      } else {
                        location.reload();
                      }
                    } else {
                      that.message({
                        message: res.message || "获取列表数据异常",
                        type: "warning",
                      });
                      // this.loading = false;
                    }
                  })
                  .error(function (error) {
                    // that.dsf.layer.toast("服务器异常", false);
                  })
                  .always(function () {
                    // that.dsf.layer.closeLoading(loader);
                  });
              } else if (toPath && !toPath.includes(curPath)) {
                // window.location.href = toPath;
                /* 回到首页（可能是分平台） */
                window.location.href = dsf.config.setting_public_pc_login_page;
              } else {
                window.location.reload();
              }
              clearTimeout(timer);
              this.loading = false;
            }, 1000);

            // 请求验证字段
            // this.dsf.http
            //   .get("user/password/getUpdatePwdRule", {}, $$webRoot.nc)
            //   .done((res) => {
            //     if (res.success) {
            //       resolve(res);
            //     } else {
            //       this.dsf.layer.message(
            //         res.message || "获取密码规则错误",
            //         false
            //       );
            //     }
            //   })
            //   .error((response) => {
            //     this.dsf.layer.message(
            //       response.message || "获取密码规则错误",
            //       false
            //     );
            //   });
          } else {
            this.getImgCode();
            this.loading = false;
            dsf.layer.pc.message(message, false);
          }
        })
        .error((err) => {
          this.loginFail?.();
          this.getImgCode();
          this.loading = false;

          dsf.layer.pc.message(err.message, false);
        })
        .always((res) => {});
      return false;
    },
    setRemindState() {
      localStorage.setItem("isRemind", this.loginForm.checked);
    },
    toChangePwd() {
      // this.$emit("changePassword");
      dsf.layer.openDialog({
        title: "短信验证",
        width: "450px",
        height: "390px",
        showClose: true,
        showCancelButton: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        params: {
          path: "/pc/nc/page/pc/login/forgetpwd",
        },
      });
    },
    toRegister() {
      this.$emit("toRegister");
    },
  },
};
</script>
<style lang="scss">
.img {
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin: 2px 8px 0;
  img {
    width: 100%;
    display: block;
  }
}
.login-box-handle {
  justify-content: flex-end !important;
  div {
    margin-right: 25px;
    color: #cd0911;
  }
}
.loginbtn {
  background-color: #cd0911 !important;
  border-radius: 6px;
}
.step-tip {
  padding: initial !important;
  color: #cd0911;
}
.img-code {
  border: 1px solid #b2b2b2;
  border-radius: 6px;
}
.login-box-info {
  .show-pwd {
    color: #666 !important;
  }
}
</style>
