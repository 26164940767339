<template>
  <div v-if="isDesign" class="ds_channel_card">
    <div class="channel_card_wrap">
      <div class="cover"><dsf-nc-image :error-src="defaultSrc" /></div>
      <div class="desc">
        <h3 class="desc-title"><span class="name ell">1</span></h3>
        <div class="info">
          <div>资源数: 8 ｜ 学时: 3</div>
          <div class="subscrip">订阅人数: 1382</div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="ds_channel_card" @click="click">
    <div class="channel_card_wrap">
      <div class="cover"><dsf-nc-image :src="data['cover'] | imgFormat" :error-src="defaultSrc" /></div>
      <div class="desc">
        <h3 class="desc-title">
          <span class="name1 ell2" :title="data.title || ''">{{ data.title || "" }}</span>
        </h3>
        <div class="info">
          <div>课程数: {{ data.course_count || 0 }} ｜ 学时: {{ data.period || 0 }}</div>
          <!-- <div class="subscrip">订阅人数: {{ data.subscribed_num || 0 }}</div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: 'DsfNcChannelCardNew',
  ctrlCaption: '学习专区卡片-新',
  mixins: [$mixins.card],
  props: {
    keysMap: {
      type: Array,
      default() {
        return [
          { from: 'nc_class_page_mainData.cover', to: 'cover', type: 'text', desc: '封面' },
          { from: 'nc_channel_page_mainData.title', to: 'title', type: 'text', desc: '标题' },
          { from: 'nc_class_page_mainData.course_count', to: 'course_count', type: 'text', desc: '课程数' },
          { from: 'nc_class_page_mainData.period', to: 'period', type: 'text', desc: '学时' },
          { from: 'nc_class_page_mainData.subscribed_num', to: 'subscribed_num', type: 'text', desc: '订阅数' }
        ];
      }
    },

    // 跳转方式
    clickConfig: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img
    };
  },
  computed: {
    isLogin() {
      return (dsf.getToken() && dsf.getToken() != "") || false;
    },
  },

  mounted() {},

  methods: {
    loadChannelList() {},

    async click() {
      /*  if (this.$listeners["click"]) {
        this.$dispatch("click", this.local);
      } else {
        if (!this.clickConfig) return;

        let { url, state } = this.clickConfig;
        url = this.$replace(url, this.local);

        this.$open({
          url,
          state,
        });
        this.clickAfter();
      } */
      /* 获取当前专栏是否公开以及是否停用 */
      let id = this.local['_id'];
      if(!id) return;

      let item = {
        url: `#/pc/nc/page/pc/commonpage/classcolumn?id=${id}`,
        opentype_value: 2,
      }
      let { success, message, data } = await this.getChannelState({ id });
      let state = data.state;
      if (state == 1) {
        dsf.layer.pc.message('专栏不存在', false);
      } else if (state == 2) {
        dsf.layer.pc.message('当前专栏已停用，请尝试学习其他专栏', false);
      } else if (state == 3) {
        /* 需要登录 */
        dsf.layer.alert('当前专栏需要登录后才能学习，点击确定进行登录').then((res) => {
          this.$checkLogin();
        });
      } else if (state == 0) {
        /* 0为能正常进入专栏 */
        if (this.isLogin) {
          /* 如果登录验证是否报名过专栏 */
          let { success, message, data } = await this.verifyColumnSign({ id });
          if (success && data.sign) {
            this.$open({
              url: item.url,
              state: item.opentype_value
            });
          } else {
            dsf.layer.confirm({ message: '请确认是否报名学习本专栏？', confirmButtonText: '确认' }).then(async (res) => {
              /* 报名 */
              let { success, message, data } = await this.columnSign({ id });
              if (success) {
                this.$open({
                  url: item.url,
                  state: item.opentype_value
                });
              }
            });
          }
        } else {
          this.$open({
            url: item.url,
            state: item.opentype_value
          });
        }
      }
    },
    verifyColumnSign(params){
      const mainUrl = "/nc/pack/sign/valid";
      return new Promise((reslove, reject) => {
        this.dsf.http
          .get(mainUrl, params)
          .done(function (res) {
            if(res.success){
              reslove(res);
            }else{
              dsf.layer.pc.message(res.message, false);
              reject(res);
            }
          })
          .error(function (error) {
            dsf.layer.pc.message("服务器异常", false);
          })
          .always(function () {
          });
      });
    },
    columnSign(params){
      const mainUrl = "/nc/pack/sign/add";
      return new Promise((reslove, reject) => {
        this.dsf.http
          .get(mainUrl, params)
          .done(function (res) {
            if(res.success){
              reslove(res);
            }else{
              dsf.layer.pc.message(res.message, false);
              reject(res);
            }
          })
          .error(function (error) {
            dsf.layer.pc.message("服务器异常", false);
          })
          .always(function () {
          });
      });
    },
    getChannelState(params) {
      const mainUrl = "/nc/pack/getState";
      return new Promise((reslove, reject) => {
        this.dsf.http
          .get(mainUrl, params)
          .done(function (res) {
            if(res.success){
              reslove(res);
            }else{
              dsf.layer.pc.message(res.message, false);
              reject(res);
            }
          })
          .error(function (error) {
            dsf.layer.pc.message("服务器异常", false);
          })
          .always(function () {
          });
      });
    },
  }
});
</script>
