<template>
  <div class="dsf_login">
    <div class="close-btn">
      <dsf-icon name="guanbi2" @click="$root.close()"></dsf-icon>
    </div>
    <div class="dsf_login_inner" :style="{ width: boxWidth }">
      <div class="dsf_login_left">
        <div class="title">扫一扫登录</div>
        <div class="sec_title">请使用贵州网院app扫码登录</div>
        <div class="login_qrcode">
          <div class="stale-dated" @click="getUuid" v-if="qecodeIsStale">
            <p>二维码已过期</p>
            <p>点我刷新</p>
          </div>
          <div
            class="code"
            :class="[qecodeIsStale ? 'stale-dated-op' : '']"
            id="code"
            ref="code"
          ></div>
        </div>
        <div class="reload_btn" @click="getUuid">刷新二维码</div>
      </div>
      <el-tabs :tab-position="tabPosition">
        <el-tab-pane label="账号登录">
          <textLogin
            :sourceUrl="sourceUrl"
            :nameHolder="nameHolder"
            :pwdHolder="pwdHolder"
            :codeHolder="codeHolder"
            :telCodeHolder="telCodeHolder"
            :toUrl="toUrl"
            :login-success="_loginSuccess"
            :login-fail="_loginFail"
            @changePassword="changePassword"
            @toRegister="toRegister"
            :isreloadCurrentPage="isreloadCurrentPage"
          />
        </el-tab-pane>
        <el-tab-pane label="CA登录" v-if="dsf.config.setting_nc_tenement_ca_login == 1">
          <caLogin/>
        </el-tab-pane>
        <!-- <el-tab-pane label="验证码登录">
          <codeLogin
            :login-success="_loginSuccess"
            :login-fail="_loginFail"
            @changePassword="changePassword"
            @toRegister="toRegister"
            :isreloadCurrentPage="isreloadCurrentPage"
          />
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { textLogin, codeLogin, caLogin } from "../../base/login";
import logoImage from "../../../assets/imgs/logo.png";
export default dsf.component({
  name: "DsfNcNewDialogLogin",
  ctrlCaption: "登录",
  mixins: [$mixins.control],
  props: {
    title: {
      type: String,
      default: "登录",
    },
    wsSource: {
      type: String,
      // default: "ws://61.243.3.22:8089/websocket",
      default: "wss://new.gzwy.gov.cn/websocket",
    },
    moreUrl: {
      type: String,
      default: "/",
    },
    boxWidth: {
      type: String,
      default: "300px",
    },
    sourceUrl: {
      type: String,
      default: "/nc/login/login",
    },
    nameHolder: {
      type: String,
      default: "请输入账号",
    },
    pwdHolder: {
      type: String,
      default: "请输入密码",
    },
    codeHolder: {
      type: String,
      default: "动态验证码",
    },
    telCodeHolder: {
      type: String,
      default: "手机验证码",
    },
    toUrl: {
      type: String,
      default: "",
    },
    loginSuccess: {
      type: Function,
      required: false,
    },
    loginFail: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      isreloadCurrentPage: false,
      qrcode: null, //二维码实例
      qecodeIsStale: false, //二维码是否过期
      ws: null,
    };
  },
  created() {},
  mounted() {
    this.getUuid();

    this.isreloadCurrentPage =
      this.$dialog.dialogArgs?.isreloadCurrentPage || false;
  },
  components: {
    textLogin,
    codeLogin,
    caLogin,
  },
  methods: {
    getUuid() {
      this.$http
        .get("/login/getUuid", {}, $$webRoot.nc)
        .done((res) => {
          if (res.success) {
            // this.createQrCode(res.data);
            this.createWs(res.data);
          } else {
            this.dsf.layer.message(res.message || "获取数据异常", false);
          }
        })
        .error((response) => {
          this.dsf.layer.message(response.message || "获取数据异常", false);
        })
        .always(function () {
          // dsf.layer.pc.closeLoading(loading);
        });
    },
    setCookie(name, value, expires) {
      var date = new Date();
      date.setTime(date.getTime() + expires * 60 * 60 * 1000);
      document.cookie =
        name + "=" + (name == 'user_name' ? value : escape(value)) + ";expires=" + date.toGMTString()+";path=/";
    },

    createWs(uuid) {
      console.log(`${this.wsSource}/${uuid}`)
      this.ws = new WebSocket(`${this.wsSource}/${uuid}`);
      //申请一个WebSocket对象，参数是服务端地址，同http协议使用http://开头一样，WebSocket协议的url使用ws://开头，另外安全的WebSocket协议使用wss://开头
      this.ws.onopen = () => {
        //当WebSocket创建成功时，触发onopen事件
        console.log("open");
        this.createQrCode(uuid);
        // ws.send("hello"); //将消息发送到服务端
      };
      this.ws.onmessage = (e) => {
        //当客户端收到服务端发来的消息时，触发onmessage事件，参数e.data包含server传递过来的数据
        // let res = JSON.parse(e.data);
        let res = JSON.parse(e.data);
        if (!res.flag) {
          return this.$layer.message("扫码成功", "success");
        } else if (res.flag == 1) {
          this.setCookie("userId", res.userId, 12);
          this.setCookie("user_id", res.userId, 12);
          this.setCookie("isLogin", 1, 12);
          // alert(res.userName)
          this.setCookie("user_name", res.userName, 12);
          this.setCookie("userKey", res.userKey, 12);
          this.setCookie("authorization_token", res.token, 12);
          this.setCookie("photoRelativePath", res?.photoRelativePath, 12);
          this.ws.close();
          // this.ws.destory();
          location.reload();
        }
      };
      this.ws.onclose = (e) => {
        //当客户端收到服务端发送的关闭连接请求时，触发onclose事件
        console.log("close");
      };
      this.ws.onerror = (e) => {
        //如果出现连接、处理、接收、发送数据失败的时候触发onerror事件
        console.log(error);
      };
    },
    createQrCode(updataUrl) {
      if (this.qrcode) {
        this.qrcode.clear(); //清除二维码
      }
      this.$nextTick(() => {
        //创建一个div，用来生成二维码
        let div = document.createElement("div");
        this.qrcode = new QRCode(div, {
          text: updataUrl,
          width: 150, //图像宽度
          height: 150, //图像高度
          colorDark: "#000000", //前景色
          colorLight: "#ffffff", //背景色
          typeNumber: 4,
          correctLevel: QRCode.CorrectLevel.H, //容错级别 QRCode.CorrectLevel.L QRCode.CorrectLevel.M QRCode.CorrectLevel.Q QRCode.CorrectLevel.H
        });

        let logo = new Image();
        logo.crossOrigin = "Anonymous";
        logo.src = logoImage;
        //生成log图片
        logo.onload = () => {
          let container = this.$refs.code;
          //获取页面上的div,可以document.querySelector()等等方法
          if (container.innerHTML != "") {
            //获取页面div，有则清除
            container.innerHTML = "";
          }
          //获取二维码
          let qrImg = this.qrcode._el.getElementsByTagName("img")[0];
          //获取canvas
          let canvas = this.qrcode._el.getElementsByTagName("canvas")[0];
          console.log("qrImg---", updataUrl);
          let cxt = canvas.getContext("2d");

          //写入logo
          cxt.drawImage(logo, 150 * 0.5 - 22, 150 * 0.5 - 22, 44, 44);
          qrImg.src = canvas.toDataURL();
          container.appendChild(this.qrcode._el);
        };

        this.qrcode.makeCode(updataUrl); //生成另一个新的二维码
      });
    },

    yes() {
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    _loginSuccess() {
      this.resolve?.(true);
      this.loginSuccess?.();
    },
    _loginFail() {
      this.resolve?.(false);
      this.loginFail?.();
    },
    changePassword() {
      const path = dsf.config.setting_public_login_forget_password;
      if (this.$listeners["changePwd"]) {
        this.$dispatch("changePwd", path);
      } else {
        path && dsf.layer.openWindow(path);
      }
    },
    toRegister() {
      const path = dsf.config.setting_public_login_register;
      if (this.$listeners["toRegister"]) {
        this.$dispatch("toRegister", path);
      } else {
        path &&
          this.$open({
            url: path,
            state: 3,
            width: "800px",
            height: "auto",
            minHeight: "500px",
            class: "register-dialog",
          });
      }
    },
  },
});
</script>
<style lang="scss">
.dsf_login {
  width: 100%;
  .dsf_login_inner {
    display: flex;
    width: 100% !important;
    .el-tabs__header .el-tabs__nav {
      .el-tabs__item.is-active,
      .el-tabs__item:not(.is-disabled):hover {
        color: #cd0911;
      }
      .el-tabs__active-bar {
        background-color: #cd0911;
      }
    }
    .login-box-info .icon-biyan:before {
      color: #666;
    }
    .dsf_login_left {
      width: 342px;
      position: relative;
      text-align: center;
      margin-right: 33px;
      &::after {
        content: "";
        width: 1px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background: #cd0911;
      }
      .title {
        font-weight: 800;
        font-size: 18px;
        margin-bottom: 10px;
      }
      .sec_title {
        margin-bottom: 10px;
      }
      .login_qrcode {
        display: flex;
        justify-content: space-around;
        margin: 38px 0;
        position: relative;
        .stale-dated {
          position: absolute;
          width: 150px;
          height: 150px;
          background: #ffffff99;
          color: #000;
          font-weight: 900;
          // line-height: 150px;
          letter-spacing: 2px;
          font-size: 13px;
          padding-top: 40px;
          cursor: pointer;
          user-select: none;
          p {
            line-height: 40px;
          }
        }
        img {
          width: 150px;
          height: 150px;
        }
        #code {
          width: 150px;
          height: 150px;
          pointer-events: none;
        }
        .stale-dated-op {
          opacity: 0.1;
        }
      }
      .reload_btn {
        background: #d53a45;
        border-radius: 8px;
        color: #fff;
        line-height: 40px;
        font-size: 14px;
        width: 140px;
        margin: 0 auto;
        cursor: pointer;
        &:hover {
          background: #cd0911;
        }
      }
    }
  }
}
</style>