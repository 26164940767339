<template>
  <div class="dsf_nc_education">
    <div class="title">
      <div class="imgbox">
        <img src="~@/assets/styles/img/pcimg/guizhou/bg/newb4_new.png" alt="" />
        <div class="more" @click="more">更多</div>
      </div>
    </div>
    <el-carousel
      arrow="always"
      type="card"
      indicator-position="none"
      :autoplay="false"
      height="450px !important"
      ref="carousel"
    >
      <div id="box" style="height: 100%" class="box">
        <el-carousel-item
          v-for="item in list"
          :key="item"
          :data-id="item.nc_operate_jdwh_id"
        >
          <div :style="{ height: '100%' }" class="out">
            <img :src="setImg(item.cover)" :alt="item['nc_operate_jdwh_id']" />
          </div>
        </el-carousel-item>
      </div>
    </el-carousel>

    <div class="bgimg">
      <img
        src="../../../assets/styles/img/pcimg/guizhou/bg/main-bg4.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcEducation",
  ctrlCaption: "党性教育",
  mixins: [$mixins.control],
  data() {
    return {
      downX: 0,
      downY: 0,
      delta: 50,
      times: null,
      isClick: true,
      list: [],
      img: [
        {
          urls: require("../../../assets/styles/img/pcimg/guizhou/btm-banner1.png"),
        },
        {
          urls: require("../../../assets/styles/img/pcimg/guizhou/btm-banner2.png"),
        },
        {
          urls: require("../../../assets/styles/img/pcimg/guizhou/btm-banner3.png"),
        },
      ],
    };
  },
  created() {
    this.showList();
  },
  mounted() {
    let that = this;
    var el = document.querySelector(".box");
    this.mouseDownAndMove(el, (e) => {
      let deltaX = e.clientX - that.deltaX;
      let deltaY = e.clientY - that.clientY;
      /* 防抖 */
      if (that.times) {
        clearTimeout(this.times);
      }
      that.times = setTimeout(() => {
        //调用接口
        if (
          Math.abs(deltaX) > that.delta &&
          Math.abs(deltaX) > Math.abs(deltaY)
        ) {
          if (deltaX >= 0) {
            console.log("右滑");
            that.$refs.carousel.prev();
          } else {
            console.log("左滑");
            that.$refs.carousel.next();
          }
          that.isClick = false;
        }
      }, 100);
    });
  },
  methods: {
    mouseDownAndMove(el, callback) {
      let that = this;
      // 添加鼠标按下监听
      // var el = document.querySelector('.el-carousel');
      el.addEventListener("mousedown", function (e) {
        console.log("按下触发");
        e.preventDefault(); /* 阻止图片的默认事件 */
        that.deltaX = e.clientX;
        that.clientY = e.clientY;
        that.isClick = true;
        // 当鼠标按下时, 添加鼠标移动监听
        el.addEventListener("mousemove", callback);
      });

      // 添加鼠标弹起监听 , 即鼠标不在按下
      el.addEventListener("mouseup", function (e) {
        console.log("抬起触发");
        // 此时移除 鼠标移动监听,移除指定 事件函数
        el.removeEventListener("mousemove", callback);
        setTimeout(() => {
          if (that.isClick) {
            //let id = e.path[2].getAttribute("data-id") || "";
            let id = $(e.target).attr("alt");
            if (id) {
              if (dsf.getCookie("isLogin")) {
                that.godetail(id);
              } else {
                that.islogin();
              }
            }
          }
        }, 200);
      });
    },

    // 是否登录
    islogin() {
      this.$openDialog({
        title: "",
        noTitle: true,
        width: "800px",
        height: dsf.config.setting_nc_other_dx_login == '1' ? "430px" : "370px",
        class: "test",
        params: {
          path: "/pc/nc/pagelogin/dialogLogin",
        },
      });
    },
    more() {
      if (dsf.getCookie("isLogin")) {
        window.open(`/ncIndex.html#/pc/nc/page/pc/education/list`);
      } else {
        this.islogin();
      }
    },
    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      }
      return src;
    },
    showList() {
      let that = this;
      const mainUrl = "/nc/jdwh/pageHomeList";

      const params = {};
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.list = res.data;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //  that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });
    },
    godetail(id) {
      // if (dsf.getCookie("isLogin")) {
      window.open(`/ncIndex.html#/pc/nc/page/pc/education/detail?id=${id}`);
      // } else {
      // this.islogin();
      // }
    },
  },
});
</script>

<style>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
