<template>
  <div class="dsf_nc_gzslider">
    <div class="left_view" ref="leftview">
      <ul class="list" ref="leftlist">
        <li
          class="item"
          v-for="(item, index) in leftview"
          :key="index"
          @mouseenter="isshow(item.suspendUrl)"
          @mouseleave="isshow(item.suspendUrl)"
          @click="tothree(item.detail, index)"
        >
          <div class="img">
            <img :src="item.imgUrl" alt="" />
          </div>
          <span>{{ item.info }}</span>
        </li>
      </ul>
      <div id="leftupbtn" @click="off_leftview(leftlist)" ref="leftupbtn">
        <img
          ref="leftimg"
          src="~@/assets/styles/img/pcimg/guizhou/left/icon-up.png"
          alt=""
        />
      </div>
    </div>
    <div class="right_view" ref="rightview">
      <ul class="list">
        <li
          class="item"
          v-for="(item, index) in rightview"
          :key="index"
          @mouseenter="isRightShow(item)"
          @mouseleave="isRightShow(item)"
          @click="gothree(index)"
        >
          <div class="img">
            <img :src="item.imgUrl" alt="" />
          </div>
          <span>{{ item.info }}</span>
          <div class="tel" v-if="item.info == '热线电话'" v-show="showTel">
            <p>技术支持: 0851-85505045</p>
            <p>联系电话: 0851-85505037</p>
            <p>联系邮箱: gzwy85528952@126.com</p>
          </div>
          <div class="er" v-if="item.suspendUrl" v-show="show">
            <img :src="item.suspendUrl" alt="" />
          </div>
        </li>
      </ul>
      <div id="rightupbtn" @click="off_rightview">
        <img
          ref="rightimg"
          src="~@/assets/styles/img/pcimg/guizhou/left/icon-up.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
dsf.ncgzslider;
export default dsf.component({
  name: "DsfNcGzSliders",
  ctrlCaption: "侧边悬浮",
  mixins: [$mixins.control],
  data() {
    return {
      show: false,
      showTel: false,
      offleft: false,
      offright: false,
      leftview: [
        /* {
          info: `贵州网院移动端`,
          imgUrl: require("../../../assets/styles/img/pcimg/guizhou/left/icon-qwxtydd.png"),
          suspendUrl: require("../../../assets/styles/img/pcimg/guizhou/left/er.png"),
        }, */
        {
          info: `中网院`,
          imgUrl: require("../../../assets/styles/img/pcimg/guizhou/left/icon-zwy.png"),
        },
        {
          info: `共产党员网`,
          imgUrl: require("../../../assets/styles/img/pcimg/guizhou/left/gcdyw.png"),
          detail: 'https://www.12371.cn/',
        },
        {
          info: `学习强国`,
          imgUrl: require("../../../assets/styles/img/pcimg/guizhou/left/icon-xx.png"),
          detail: "https://www.xuexi.cn",
        },
        {
          info: `智慧校园`,
          imgUrl: require("../../../assets/styles/img/pcimg/guizhou/left/right6.png"),
          detail: "http://oa.gzdx.gov.cn/dsf5/page.html#/pc/login",
        },
      ],
      rightview: [
      /*   {
          info: `智能客服`,
          imgUrl: require("../../../assets/styles/img/pcimg/guizhou/right/icon-znkf.png"),
        }, */
        {
          info: `贵州网院移动端`,
          imgUrl: require("../../../assets/styles/img/pcimg/guizhou/left/icon-qwxtydd.png"),
          suspendUrl: require("../../../assets/styles/img/pcimg/guizhou/left/er.png"),
        },
        {
          info: `热线电话`,
          imgUrl: require("../../../assets/styles/img/pcimg/guizhou/right/icon-rxdh.png"),
        },
        {
          info: `操作指南`,
          imgUrl: require("../../../assets/styles/img/pcimg/guizhou/right/icon-bzzx.png"),
        },
        {
          info: `用户反馈`,
          imgUrl: require("../../../assets/styles/img/pcimg/guizhou/right/icon-userfq.png"),
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 是否登录
    islogin() {
      this.$openDialog({
        title: "",
        noTitle: true,
        width: "800px",
        height: dsf.config.setting_nc_other_dx_login == '1' ? "430px" : "370px",
        class: "test",
        params: {
          path: "/pc/nc/pagelogin/dialogLogin",
        },
      });
    },
    gothree(index) {
    /*   if (index == 0) {
        dsf.layer.message("暂未开放，敬请期待");
        return
      } */
      if (index == 2) {
        if (dsf.getCookie("isLogin")) {
          window.open("/ncIndex.html#/pc/nc/page/pc/commonpage/guide");
        } else {
          this.islogin();
        }
      } else if (index == 3) {
        if (dsf.getCookie("isLogin")) {
          window.open("/page.html#/pc/nc/operate/feedback/edit");
        } else {
          this.islogin();
        }
      }
    },
    tothree(url, index) {
      if (url) {
        if (dsf.getCookie("isLogin")) {
          // dsf.layer.pc.message({
          //   message: "您即将离开贵州网院",
          //   duration: 3000,
          // });
          // setTimeout(() => {
            window.open(url);
          // }, 3000);
        } else {
          this.islogin();
        }
      }
      if (index == 0) {
        if (dsf.getCookie("isLogin")) {
          let that = this;
          const mainUrl = "/inc/cela/login";
          const params = {
            type: "pc",
          };
          that.dsf.http
            .get(mainUrl, params)
            .done(function (res) {
              if (res.success) {
                // dsf.layer.pc.message({
                //   message: "您即将离开贵州网院，如出现问题及时联系电话：4008-699-999",
                //   duration: 3000,
                // });
                // setTimeout(() => {
                  window.open(res.data);
                // }, 3000);
              } else {
                that.$message({
                  message: res.message || "获取列表数据异常",
                  type: "warning",
                });
              }
            })
            .error(function (error) {
              that.dsf.layer.toast("服务器异常", false);
            })
            .always(function () {
              // dsf.layer.pc.closeLoading(loader);
            });
        } else {
          this.islogin();
        }
      }
    },
    isRightShow(item){
      if(item.info == "热线电话"){
        this.showTel = !this.showTel;
      }
      if(item.info == "贵州网院移动端"){
        this.show = !this.show;
      }
    },
    isshow(suspendUrl) {
/*       if (suspendUrl) {
        this.show = !this.show;
      } */
    },
    off_leftview() {
      if (!this.offleft) {
        this.$refs.leftview.style.overflow = "hidden";
        this.$refs.leftview.style.height = 30 + "px";
        this.$refs.leftview.style.transition = "all .5s";
        this.offleft = !this.offleft;
        this.$refs.leftimg.style.transform = "rotateZ(180deg)";
      } else {
        this.$refs.leftimg.style.transform = "rotateZ(0deg)";
        this.$refs.leftview.style.height = 555 + "px";
        this.$refs.leftview.style.transition = "all .5s";
        this.offleft = !this.offleft;
        this.$refs.leftview.style.overflow = "none";
      }
    },
    off_rightview() {
      if (!this.offright) {
        this.$refs.rightview.style.overflow = "hidden";
        this.$refs.rightview.style.height = 30 + "px";
        this.$refs.rightview.style.transition = "all .5s";
        this.offright = !this.offright;
        this.$refs.rightimg.style.transform = "rotateZ(180deg)";
      } else {
        this.$refs.rightview.style.height = 540 + "px";
        this.$refs.rightview.style.transition = "all .5s";
        this.offright = !this.offright;
        this.$refs.rightview.style.overflow = "none";
        this.$refs.rightimg.style.transform = "rotateZ(0deg)";
      }
    },
  },
});
</script>

<style></style>
