<template>
  <div class="dsf_nc_forgetpwd">
    <div class="login-tab-content">
      <div class="login-tab-content-item" style="display: block">
        <div class="login-activation-mobile">
          <div class="login-activation-after login-activation-wrap">
            <label>姓名</label>
            <input v-model.trim="userForm.name" type="text" placeholder="请输入姓名" />
          </div>
          <div class="login-activation-after login-activation-wrap">
            <label>学员编号</label>
            <input v-model.trim="userForm.code" type="text" placeholder="请输入学员编号" />
          </div>
          <div class="login-activation-after login-activation-wrap">
            <label>绑定手机号</label>
            <input v-model.number.trim="userForm.phone" type="text" maxlength="11" placeholder="请输入手机号" />
          </div>
          <div class="login-activation-wrap">
            <input type="text" v-model.number.trim="userForm.phoneCode" placeholder="请输入短信验证码" class="login-activation-code" maxlength="6" />
            <button class="login-code-btn activecode" @click="getTelCode">
              {{ telCodeText }}
            </button>
          </div>
          <button class="login-btn" @click="save">确定</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: 'DsfNcPhoneBind',
  ctrlCaption: '贵州手机号绑定',
  mixins: [$mixins.control],
  props: {
    saveUrl: {
      type: String,
      default: '/login/bindPhone'
    },
    phoneUrl: {
      type: String,
      default: '/login/sendBindVCode'
    }
  },
  components: {},
  data() {
    return {
      userForm: {
        name: '',
        code: '',
        phone: '',
        phoneCode: '',
        userId: '',
        loginName: '',
      },
      isCodeLoading: false,
      isSaveLoading: false,
      telCodeText: '获取手机验证码',
      telTimeCount: 60
    };
  },
  created() {
    dsf.mix(this.userForm, this.queryString);
  },
  mounted() {},
  methods: {
    save() {
      let that = this;
      const { phone, loginName, userId, phoneCode: vCode } = this.userForm;
      if (!this.validateFrom()) {
        return;
      }
      if (vCode == '') {
        dsf.layer.message('验证码不能为空！', false);
        return;
      }
      if (this.isSaveLoading) return;
      this.isSaveLoading = true;
      const loading = dsf.layer.loading();
      let url = this.$replace(this.saveUrl, this);
      dsf.http
        .post(
          url,
          {
            userId,
            loginName,
            phone,
            vCode
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          },
          $$webRoot.nc
        )
        .done(({ message, state, data, success }) => {
          if (success) {
            dsf.layer
              .alert({ message: '手机号绑定成功，去登录！', showClose: false })
              .then(() => {
                window.location.reload();
              })
              .catch(() => {})
              .finally(() => {});
          } else {
            error(message);
          }
        })
        .error((err) => {
          error(err.message);
        })
        .always((res) => {
          dsf.layer.closeLoading(loading);
        });
      function error(message) {
        dsf.layer.message(message, false);
        that.isSaveLoading = false;
      }
    },
    getTelCode() {
      let that = this;
      const { phone, name, userId, loginName } = this.userForm;
      if (!this.validateFrom()) {
        return;
      }
      if (this.isCodeLoading) return;
      this.isCodeLoading = true;
      const loading = dsf.layer.loading();
      let url = this.$replace(this.phoneUrl, this);
      dsf.http
        .post(
          url,
          {
            userId,
            loginName,
            phone
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          },
          $$webRoot.nc
        )
        .done(({ message, state, data, success }) => {
          if (success) {
            dsf.layer.message(message);
            let timer = setInterval(() => {
              this.telTimeCount--;
              if (this.telTimeCount <= 0) {
                this.telTimeCount = 60;
                this.isCodeLoading = false;
                this.telCodeText = '重新获取';
                clearInterval(timer);
              } else {
                this.telCodeText = `${this.telTimeCount}秒后重新获取`;
              }
            }, 1000);
          } else {
            error(message);
          }
        })
        .error((err) => {
          error(err.message);
        })
        .always((res) => {
          dsf.layer.closeLoading(loading);
        });
      function error(message) {
        dsf.layer.message(message, false);
        that.isCodeLoading = false;
      }
    },
    validateFrom() {
      const { phone, name, code } = this.userForm;
      if (name == '') {
        dsf.layer.message('姓名不能为空！', false);
        return false;
      }
      if (code == '') {
        dsf.layer.message('学员编号不能为空！', false);
        return false;
      }
      if (phone == '') {
        dsf.layer.message('手机号不能为空！', false);
        return false;
      }
      if (!this.validatePhone(phone)) {
        dsf.layer.message('手机号格式错误！', false);
        return false;
      }
      return true;
    },
    validatePhone(phoneNumber) {
      if (!phoneNumber) return false;
      const regex = /^1[3-9]\d{9}$/;
      return regex.test(phoneNumber);
    }
  }
});
</script>
<style></style>
