<template>
  <div class="dsf_special_column" v-if="isDesign">
    <div class="item_inner">
      <!-- @click="$emit('toDetail', item.id)" -->
      <div class="item-left-img">
        <el-image :src="'' | imgFormat"></el-image>
        <span class="item-type">专题班</span>
        <span class="item-time">2012-10-20 ~ 2012-10-25</span>
      </div>
      <div class="item-right-desc">
        <div class="title">
          <span class="name ellipsis-2">我是一个标题</span>
          <span class="task">任务</span>
        </div>
        <p class="item-teacher-name">
          <span>张三</span>
          <span>项目经理</span>
        </p>
        <ul class="item-bottom-desc">
          <li>
            <span class="desc-span">必修</span>
            10门课程
          </li>
          <li>
            <span class="desc-span">选修</span>
            5门课程
          </li>
        </ul>
      </div>
      <div class="circle-wrap">
        <el-progress width="60" stroke-width="5" :color="curColor" type="circle" :percentage="33"
          class="progress-circle"></el-progress>
        <p>当前进度</p>
      </div>
    </div>
  </div>
  <div class="dsf_special_column" v-else>
    <div class="item_inner">
      <!-- @click="$emit('toDetail', item.id)" -->
      <div class="item-left-img" @click="_clickBefore(click)">
        <dsf-nc-image :src="data['cover'] | imgFormat" :error-src="defaultSrc" />
        <span class="item-type">{{ data.typeText }}</span>
        <span class="item-time">{{ data.sTime }} ~ {{ data.endTime }}</span>
      </div>
      <div class="item-right-desc">
        <div class="title" @click="_clickBefore(click)">
          <span class="item-need" v-if="data.isNeed">{{ data.isNeed }}</span>
          <span class="name ellipsis-2">{{ data.title }}</span>
          <span class="task" v-if="data.isTask">任务</span>
        </div>
        <p class="item-teacher-name">
          <span>{{ data.cTeacher }}</span>
          <span>{{ data.cJobUnit }}</span>
        </p>
        <ul class="item-bottom-desc">
          <li>
            <span class="desc-span">必修</span>
            {{ data.cPeriod || 0 }}门课程
          </li>
          <li>
            <span class="desc-span">选修</span>
            {{ data.cTagNum || 0 }}门课程
          </li>
        </ul>
      </div>
      <div class="circle-wrap">
        <el-progress width="60" stroke-width="5" :color="curColor" type="circle" :percentage="percentage"
          class="progress-circle" :format="formatPercent"></el-progress>
        <!-- <p>当前进度</p> -->
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcGzCardSpecialColumn",
  ctrlCaption: "贵州-学习任务-专题班卡片",
  mixins: [$mixins.card],
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
    };
  },
  props: {
    title: {
      type: String,
      default: "标题",
    },
    menus: {
      type: Array,
      default: () => {
        return [];
      },
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "cover", to: "cover", type: "text", desc: "封面" },
          { from: "typeText", to: "typeText", type: "boolean", desc: "类型" },
          { from: "sTime", to: "sTime", type: "text", desc: "开始时间" },
          { from: "endTime", to: "endTime", type: "text", desc: "结束时间" },
          { from: "title", to: "title", type: "text", desc: "标题" },
          { from: "isTask", to: "isTask", type: "text|number", desc: "任务" },
          { from: "cTeacher", to: "cTeacher", type: "text|number", desc: "教师" },
          { from: "cJobUnit", to: "cJobUnit", type: "text|number", desc: "职位" },
          { from: "cPeriod", to: "cPeriod", type: "text|number", desc: "必修" },
          { from: "cTagNum", to: "cTagNum", type: "text|number", desc: "选修" },
          { from: "cCommentNum", to: "cCommentNum", type: "text|number", desc: "评论数" },
          { from: "finishedRateOrScore", to: "finishedRateOrScore", type: "text|number", desc: "完成度" },
          { from: "id", to: "id", type: "text|number", desc: "id" },
          { from: "status_value", to: "status_value", type: "text|number", desc: "结业状态" },
          // { from: "isNeed", to: "isNeed", type: "text|number", desc: "必修专题" },
        ];
      },
    },
    // 跳转方式
    clickConfig: {
      type: Object,
      default: null,
    },
  },
  computed: {
    curColor() {
      return this.$root.currentTheme["normal"];
    },
    percentage(){
      return this.data.status_value == '2' ? 100 : 0;
    },
  },
  mounted() { },
  methods: {
    click() {
      if (this.$listeners["toDetails"]) {
        this.$emit("toDetails",this.data)
      } else {
        if (!this.clickConfig) return;
        let { url, state } = this.clickConfig;
        url = this.$replace(url, this.local);
        this.$open({ url, state });
      }

    },
    formatPercent(it) {
      return this.data.status_value == '2' ? '已结业' : '未结业';
      // return it || "0";
    },
  },
});
</script>
