<template>
  <div class="dsf_nc_everyclass">
    <div class="wrapper">
      <swiper ref="mySwiper" v-bind:options="swiperOption">
        <swiper-slide v-for="(item, index) in list" :key="index">
          <div class="item">
            <div class="imgbox">
              <img
                @click="godetail(item['nc_courses_info_id'])"
                class="leftimg"
                :src="setImg(item.cover)"
                alt="error"
              />
              <img
                class="rightimg"
                src="../../../assets/styles/img/pcimg/guizhou/b1-mzyk.png"
                alt=""
              />
            </div>
            <div class="desc">
              <div class="title">{{ item.title }}</div>
              <div class="content" v-html="item.content"></div>
              <div class="detail" @click="godetail(item['nc_courses_info_id'])">
                <span>查看详细</span>
                <img
                  src="~@/assets/styles/img/pcimg/guizhou/icon-ckxq.png"
                  alt=""
                />
                <dialog :show="show"></dialog>
              </div>
            </div>
          </div>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
    <div class="img">
      <img
        class="bgimg"
        src="../../../assets/styles/img/pcimg/guizhou/bg/main-bg1.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
// navigation是箭头的重点
import {
  Swiper as SwiperClass,
  Pagination,
  Navigation,
} from "swiper/swiper.esm";
SwiperClass.use([Pagination, Navigation]);
// import Dialog from "../../common/Dialog/Dialog.vue";
export default dsf.component({
  name: "DsfNcEveryClass",
  ctrlCaption: "每周一课",
  mixins: [$mixins.control],
  components: {
    Swiper,
    SwiperSlide,
    // Dialog,
  },

  data() {
    return {
      list: [],
      datahtml: "",
      contentTtle: "",
      swiperOption: {
        // loop: true,
        direction: "vertical",
        pagination: {
          type: "fraction",
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  created() {
    this.showList();
  },
  mounted() {},
  methods: {
    islogin() {
      this.$openDialog({
        title: "",
        noTitle: true,
        width: "800px",
        height: dsf.config.setting_nc_other_dx_login == '1' ? "430px" : "370px",
        class: "test",
        params: {
          path: "/pc/nc/pagelogin/dialogLogin",
        },
      });
    },
    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      }
      return src;
    },
    godetail(id) {
      if (dsf.getCookie("isLogin")) {
        window.open(`/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}`);
      } else {
        this.islogin();
      }
    },
    showList() {
      const that = this;
      const mainUrl = "/nc/mzyk/courseList";
      const params = {};
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.list = res.data;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //  that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>

<style>
</style>
